import React, { useState } from 'react'
import Layout from '../components/layout/layout'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  HStack,
  Img,
  Select,
  Text,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { useShopLogic } from '../components/page/shop/shopLogic'
import { useMutation } from 'react-query'
import { CartButton } from '../components/page/shop/cart'
import { formatCurrency } from '../components/page/shop/elements'
import { useTimeoutFn } from 'react-use'
import sadhguruSignature from '../images/shop/sadhguruSignature.svg'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

function Header({ data }) {
  const image = withArtDirection(getImage(data.headerMobile), [
    { media: '(min-width: 30em', image: getImage(data.headerTablet) },
    { media: '(min-width: 62em', image: getImage(data.headerDesktop) },
  ])
  return (
    <Box position={'relative'}>
      <Box
        as={GatsbyImage}
        image={image}
        alt={'Background'}
        loading={'eager'}
        height={[
          '120vw',
          '60vw',
          'calc(530/1521 * 100vw)',
          'calc(542/1913 * 100vw)',
        ]}
        objectPosition={'center bottom'}
      />
      <Flex
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box textAlign={'center'} px={3}>
          <Heading
            as={'h1'}
            textAlign={'center'}
            mb={6}
            fontSize={['30px', '32px', '40px', '52px']}
          >
            “Karma means you are the maker of your life.”
          </Heading>
          <Img
            src={sadhguruSignature}
            width={{ base: '100px', md: '140px', lg: '160px' }}
            mx={'auto'}
          />
        </Box>
      </Flex>
    </Box>
  )
}

function Product({ images, title, priceRange, variants, addItem, isLoading }) {
  const [variant, setVariant] = useState(variants[0].shopifyId)
  let addItemMutation = {}
  const [, cancel, reset] = useTimeoutFn(() => {
    addItemMutation.reset()
  }, 3000)

  addItemMutation = useMutation((id) => addItem(id), {
    onMutate: () => {
      cancel()
    },
    onSuccess: () => {
      reset()
    },
  })

  return (
    <Box maxWidth={'360px'}>
      <Img src={images[0].originalSrc} mb={3} width={'100%'} />
      <HStack justifyContent={'space-between'}>
        <Text fontWeight={'bold'}>{title}</Text>
        <Text ml={4}>{formatCurrency(priceRange.maxVariantPrice.amount)}</Text>
      </HStack>
      <Text mb={3}>Ships in 2-3 business days</Text>

      <HStack spacing={2} opacity={isLoading ? 0 : 1}>
        {variants.length > 1 && (
          <>
            <Text>Size</Text>
            <Select
              value={variant}
              onChange={(event) => {
                setVariant(event.target.value)
              }}
              maxW={'60px'}
              size={'sm'}
            >
              {variants.map((variant) => (
                <option value={variant.shopifyId} key={variant.shopifyId}>
                  {variant.title}
                </option>
              ))}
            </Select>
          </>
        )}

        <Button
          onClick={() => addItemMutation.mutate(variant)}
          size={'sm'}
          isLoading={addItemMutation.isLoading}
          minW={'120px'}
        >
          {addItemMutation.isSuccess ? 'Added!' : 'Add to cart'}
        </Button>
      </HStack>
    </Box>
  )
}

function Collection({ collection, addItem, isLoading }) {
  return (
    <>
      <Heading variant={'heading'} mb={8}>
        {collection.title}
      </Heading>
      <Grid
        gridTemplateColumns={'repeat(auto-fit, minmax(280px, max-content))'}
        justifyContent={'start'}
        gridColumnGap={'3%'}
        gridRowGap={12}
        mb={16}
      >
        {collection.products.map((product) => (
          <Product
            {...product}
            key={product.handle}
            addItem={addItem}
            isLoading={isLoading}
          />
        ))}
      </Grid>
    </>
  )
}

export default function ShopPage({ data }) {
  const { checkout, addItem, isLoading, updateItem } = useShopLogic()

  return (
    <Layout>
      <Header data={data} />

      <Container maxWidth={false}>
        {data.collections.nodes.map((collection, index) => (
          <Collection
            collection={collection}
            key={index}
            addItem={addItem}
            isLoading={isLoading}
          />
        ))}

        <CartButton checkout={checkout} updateItem={updateItem} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    collections: allShopifyCollection(
      filter: {
        title: {
          regex: "/Karma Merchandise|Herbal Care|Rejuvenate|Yogic Lifestyle/"
        }
        products: { elemMatch: { availableForSale: { eq: true } } }
      }
    ) {
      nodes {
        title
        products {
          images {
            originalSrc
          }
          title
          shopifyId
          handle
          priceRange {
            maxVariantPrice {
              amount
            }
          }
          variants {
            availableForSale
            title
            shopifyId
            sku
          }
          vendor
        }
      }
    }

    headerMobile: file(relativePath: { eq: "shop/headerMobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 80)
      }
    }

    headerTablet: file(relativePath: { eq: "shop/headerTablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 80)
      }
    }

    headerDesktop: file(relativePath: { eq: "shop/headerDesktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 80)
      }
    }
  }
`
