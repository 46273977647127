import React from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'
import Bugsnag from '@bugsnag/js'

export function formatCurrency(amount) {
  try {
    const asNumber = Number.parseFloat(amount)
    return asNumber.toLocaleString('en', { style: 'currency', currency: 'USD' })
  } catch (e) {
    Bugsnag.notify(e)
    return amount
  }
}

export function NumberInput({ value, onIncrement, onDecrement, ...props }) {
  return (
    <HStack maxW='150px' {...props}>
      <Button onClick={onDecrement} size={'sm'}>
        -
      </Button>
      <Text px={1}>{value}</Text>
      <Button onClick={onIncrement} size={'sm'}>
        +
      </Button>
    </HStack>
  )
}
