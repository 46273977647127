const storage = (function () {
  const uid = new Date().toString()
  let storage
  let result
  try {
    ;(storage = window.localStorage).setItem(uid, uid)
    result = storage.getItem(uid) === uid
    storage.removeItem(uid)
    return result && storage
  } catch (exception) {}
})()

export function getLocalStorage() {
  return storage
}
