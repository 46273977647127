import { useEffect, useState } from 'react'
import Client from 'shopify-buy'
import { getLocalStorage } from '../../../utils/localStorage'
import Bugsnag from '@bugsnag/js'
import { useQuery, useQueryClient } from 'react-query'

function useCheckout(client) {
  return useQuery(
    'checkout',
    () => {
      const storage = getLocalStorage()
      return Promise.resolve()
        .then(() => {
          if (storage && storage.getItem('karma-checkout-id')) {
            return client.checkout
              .fetch(storage.getItem('karma-checkout-id'))
              .then((checkout) => {
                // Remove items in cart that may have been deleted
                // (their variant is set to null)
                const itemsToRemove = (checkout.lineItems || [])
                  .filter((item) => !item.variant)
                  .map((item) => item.id)
                if (itemsToRemove.length > 0) {
                  return client.checkout.removeLineItems(
                    checkout.id,
                    itemsToRemove
                  )
                } else {
                  return checkout
                }
              })
          }
          return undefined
        })
        .catch(Bugsnag.notify)
        .then((checkout) => {
          if (checkout) {
            return checkout
          }

          return client.checkout.create().then((checkout) => {
            console.log({ checkout })
            if (storage) storage.setItem('karma-checkout-id', checkout.id)
            return checkout
          })
        })
    },
    { staleTime: Infinity, enabled: !!client }
  )
}

export function useShopLogic() {
  const queryClient = useQueryClient()
  const [client, setClient] = useState(undefined)
  const { data: checkout, isSuccess } = useCheckout(client)

  const updateCheckout = (checkout) => {
    console.log({ checkout, items: checkout.lineItems })
    queryClient.setQueryData('checkout', checkout)
  }

  useEffect(() => {
    // Initializing a client to return content in the store's primary language
    const client = Client.buildClient({
      domain: `${process.env.GATSBY_SHOPIFY_DOMAIN}.myshopify.com`,
      storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    })
    setClient(client)
  }, [])

  const addItem = (productId) => {
    client.checkout
      .addLineItems(checkout.id, [{ variantId: productId, quantity: 1 }])
      .then(updateCheckout)
  }

  const updateItem = ({ id, quantity }) => {
    console.log('in updateItem', { id, quantity })
    client.checkout
      .updateLineItems(checkout.id, [{ id, quantity }])
      .then(updateCheckout)
  }

  return {
    client,
    checkout,
    addItem,
    updateItem,
    isLoading: !isSuccess,
  }
}
