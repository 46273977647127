import { useMutation } from 'react-query'
import {
  Box,
  Button,
  DrawerOverlay,
  Grid,
  HStack,
  Img,
  Text,
  VStack,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { formatCurrency, NumberInput } from './elements'

export function CartButton({
  checkout: { lineItems, webUrl, lineItemsSubtotalPrice } = {},
  updateItem,
}) {
  console.log({ lineItems })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const updateItemMutation = useMutation(updateItem)
  const hasProducts = lineItems && lineItems.length > 0
  const numProducts = hasProducts
    ? lineItems.reduce((total, item) => total + item.quantity, 0)
    : 0

  return (
    <>
      <Button position={'fixed'} top={'100px'} right={'12px'} onClick={onOpen}>
        <span>
          {hasProducts && (
            <Box
              as={'span'}
              fontSize={'18px'}
              transform={'translateY(-3px)'}
              display={'inline-block'}
              fontWeight={'bold'}
            >
              {numProducts}
            </Box>
          )}{' '}
          Cart
        </span>
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Cart</DrawerHeader>
          <DrawerBody flexGrow={0} flexBasis={'auto'}>
            <VStack spacing={4} alignItems={'stretch'}>
              {hasProducts ? (
                <>
                  {lineItems.map((item, index) => (
                    <Grid
                      key={item.title}
                      templateColumns={'70px 1fr min-content'}
                      alignItems={'end'}
                      columnGap={3}
                      {...(index >= 1 && {
                        borderTop: 'solid 1px black',
                        pt: 4,
                      })}
                    >
                      <Img src={item.variant.image.src} />
                      <Box>
                        <Text mb={2} fontSize={'sm'}>
                          {item.title}
                          {item.variant.title !== 'Default Title' &&
                            ` - ${item.variant.title}`}
                        </Text>
                        <NumberInput
                          value={item.quantity}
                          onIncrement={() =>
                            updateItemMutation.mutate({
                              id: item.id,
                              quantity: item.quantity + 1,
                            })
                          }
                          onDecrement={() =>
                            updateItemMutation.mutate({
                              id: item.id,
                              quantity: item.quantity - 1,
                            })
                          }
                        />
                      </Box>
                      <Text>
                        {formatCurrency(item.variant.price * item.quantity)}
                      </Text>
                    </Grid>
                  ))}
                </>
              ) : (
                <Text textAlign={'center'}>You cart is empty</Text>
              )}
            </VStack>
          </DrawerBody>
          {hasProducts && (
            <DrawerFooter
              flexDirection={'column'}
              alignItems={'stretch'}
              borderTop={'solid 1px black'}
              mt={10}
            >
              <HStack justifyContent={'space-between'} mb={4}>
                <Text>Total</Text>
                <Text>{formatCurrency(lineItemsSubtotalPrice.amount)}</Text>
              </HStack>

              <Button as={'a'} href={webUrl}>
                Check Out
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  )
}
